<template>
  <b-field label="Attributes"
    :type="value.attributesError && 'is-danger'"
    :message="value.attributesError">
    <b-input v-model="attributes" type="textarea" rows="8" :disabled="!editable">
    </b-input>
  </b-field>
</template>
<script>
export default {
  name: 'IntegrationListDetail',
  props: {
    value: { required: true },
    editable: { default: false },
  },
  data() {
    return {};
  },
  computed: {
    attributes: {
      get () {
        return JSON.stringify(this.value.attributes);
      },
      set (newValue) {
        const { value } = this;
        try {
          value.attributes = JSON.parse(newValue);
          value.attributesError = null;
        } catch (error) {
          value.attributesError = `Attributes: ${error.toString()}`;
        }
        this.$emit('update', value);
      },
    },
  },
  methods: {
  },
};
</script>
