<template>
  <div>
    <b-input v-if="show" type="textarea" :value="value" rows="3" readonly></b-input>
    <b-button v-if="show" @click="show = false" size="is-small" icon-left="eye-off">hide</b-button>
    <b-button v-else @click="show = true" size="is-small" icon-left="eye" >show</b-button>
    <b-button @click="copyToClipboard" size="is-small" icon-left="content-copy">copy</b-button>
  </div>
</template>

<script>

export default {
  name: 'ApiToken',
  props: {
    value: { required: true },
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    copyToClipboard() {
      const toast = this.$toast;
      this.$copyText(this.value).then(
        () => {
          toast.success(`API Token Copied to clipboard`);
        },
        () => {
          toast.error('Can not copy');
        }
      );
    },
  },
};
</script>
