<template>
  <div class="field">

    <div class="is-divider"></div>

    <b-field label="Order" horizontal>
      <b-input v-if="isEditable" v-model="order" type="text"></b-input>
      <span v-else>{{ order }}</span>
    </b-field>

    <b-field label="Billable" horizontal>
      <b-checkbox
        v-if="isEditable"
        v-model="billable"
      >{{ billable ? "Yes" : "No" }}</b-checkbox>
      <span v-else>{{ billable ? "Yes" : "No" }}</span>
    </b-field>

    <b-field label="Billing Period Start" horizontal>
      <Datepicker v-if="isEditable" v-model="period_start" :max-date="period_end"></Datepicker>
      <span v-else>{{ period_start }}</span>
    </b-field>

    <b-field label="Billing Period End" horizontal>
      <Datepicker v-if="isEditable" v-model="period_end" :min-date="period_start"></Datepicker>
      <span v-else>{{ period_end }}</span>
    </b-field>

    <b-field label="Billing Note" horizontal>
      <b-input v-if="isEditable" type="textarea" v-model="note" rows=2></b-input>
      <vue-simple-markdown v-else :source="note"></vue-simple-markdown>
    </b-field>

    <b-field label="Billing Cloud Service" horizontal>
      <b-select v-if="isEditable"  placeholder="Select ..." v-model="cloud_service" >
        <option
            v-for="option of cloud_service_list"
            :value="option.name"
            :key="option.name"
            :title="`Price per month: ${option.price_per_month} ${billing_currency}`">
            {{ option.name }}<span v-if="option.note"> ({{ option.note }})</span>
        </option>
      </b-select>
      <span v-else>{{ cloud_service }}</span>
    </b-field>

    <b-field label="Billing Cloud Price" horizontal>
      <b-input v-if="isEditable" v-model="cloud_price" type="number" step="0.01" style="width: 130px"></b-input>
      <span v-else>{{ cloud_price }}</span>
    </b-field>

    <b-field label="Billing Insight Service" horizontal>
      <b-select v-if="isEditable"  placeholder="Select ..." v-model="insight_service" >
        <option
            v-for="option of insight_service_list"
            :value="option.name"
            :key="option.name"
            :title="`Price per month: ${option.price_per_month} ${billing_currency}`">
            {{ option.name }}<span v-if="option.note"> ({{ option.note }})</span>
        </option>
      </b-select>
      <span v-else>{{ insight_service }}</span>
    </b-field>

    <b-field label="Billing Insight Price" horizontal>
      <b-input v-if="isEditable" v-model="insight_price" type="number" step="0.01" style="width: 130px"></b-input>
      <span v-else>{{ insight_price }}</span>
    </b-field>

    <b-field label="Billing Rental Service" horizontal>
      <b-select v-if="isEditable"  placeholder="Select ..." v-model="rental_service" >
        <option
            v-for="option of rental_service_list"
            :value="option.name"
            :key="option.name"
            :title="`Price per month: ${option.price_per_month} ${billing_currency}`">
            {{ option.name }}<span v-if="option.note"> ({{ option.note }})</span>
        </option>
      </b-select>
      <span v-else>{{ rental_service }}</span>
    </b-field>

    <b-field label="Billing Rental Price" horizontal>
      <b-input v-if="isEditable" v-model="rental_price" type="number" step="0.01" style="width: 130px"></b-input>
      <span v-else>{{ rental_price }}</span>
    </b-field>

  </div>
</template>

<script>
import Datepicker from './Datepicker.vue';
import variable from '../variable';

function findByName(list, name) {
  for (let i = 0, l = list.length; i < l; i += 1) {
    if (list[i].name === name) return list[i];
  }
  return {};
}

export default {
  props: ['value', 'editable', 'organization_id'],
  model: {
    prop: 'value',
    event: 'change',
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      cloud_service_list: [],
      insight_service_list: [],
      rental_service_list: [],
      billing_currency: null,
    };
  },
  created() {
    this.fetch();
  },
  computed: {
    isEditable () {
      return this.isAdmin && this.editable;
    },
    isAdmin() {
      return this.$store.state.userIsAdmin;
    },
    connectivityOprions () {
      return [null, ...variable.connectivityList];
    },
    visualisationOprions () {
      return [null, ...variable.visualisationList];
    },
    order: {
      get() { return this.value ? this.value.order : null; },
      set(value) { this.update('order', value); },
    },
    billable: {
      get() { return this.value ? this.value.billable : true; },
      set(value) { this.update('billable', value); },
    },
    period_start: {
      get() { return this.value ? this.value.period_start : new Date(); },
      set(value) { this.update('period_start', value); },
    },
    period_end: {
      get() { return this.value ? this.value.period_end : null; },
      set(value) { this.update('period_end', value); },
    },
    note: {
      get() { return this.value ? this.value.note : null; },
      set(value) { this.update('note', value); },
    },
    cloud_service: {
      get() { return this.value ? this.value.cloud_service : null; },
      set(value) {
        this.update('cloud_service', value);
        this.cloud_price = findByName(this.cloud_service_list, value).price_per_month;
      },
    },
    cloud_price: {
      get() { return this.value ? this.value.cloud_price : null; },
      set(value) { this.update('cloud_price', value); },
    },
    insight_service: {
      get() { return this.value ? this.value.insight_service : null; },
      set(value) {
        this.update('insight_service', value);
        this.insight_price = findByName(this.insight_service_list, value).price_per_month;
      },
    },
    insight_price: {
      get() { return this.value ? this.value.insight_price : null; },
      set(value) { this.update('insight_price', value); },
    },
    rental_service: {
      get() { return this.value ? this.value.rental_service : null; },
      set(value) {
        this.update('rental_service', value);
        this.rental_price = findByName(this.rental_service_list, value).price_per_month;
      },
    },
    rental_price: {
      get() { return this.value ? this.value.rental_price : null; },
      set(value) { this.update('rental_price', value); },
    },
  },
  methods: {
    update(key, value) {
      this.$set(this.value, key, value);
      this.$emit('change', this.value);
    },
    async fetch() {
      if (!this.organization_id || !this.isEditable) return;
      const { data } = await this.$http.get(
        'v1/billing/contracts',
        { params: { organization_id: this.organization_id } }
      );
      if (data && data.length === 1) {
        this.cloud_service_list = data[0].cloud_service_list;
        this.cloud_service_list.unshift({ name: '' });
        this.insight_service_list = data[0].insight_service_list;
        this.insight_service_list.unshift({ name: '' });
        this.rental_service_list = data[0].rental_service_list;
        this.rental_service_list.unshift({ name: '' });
        this.billing_currency = data[0].billing_currency;
      }
    },
  },
  watch: {
    organization_id() {
      this.fetch();
    },
    editable() {
      this.fetch();
    },
  },
};
</script>
