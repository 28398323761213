<template>
  <div>
    <b-table :data="integrations" detailed detail-key="id">
      <b-table-column field="name" label="Name" v-slot="props">
        <span :class="{ 'is-danger': props.row.attributesError }" :title="props.row.errorMsg">
          {{ props.row.name }}
        </span>
      </b-table-column>

      <b-table-column field="type" label="Type" width="150" v-slot="props">
        {{ props.row.type }}
      </b-table-column>

      <b-table-column field="enabled" label="Enabled" width="150" v-slot="props">
        <b-checkbox v-if="editable" v-model="props.row.enabled" @input="updateIntegrationEnabled(props.row)">
          {{ props.row.enabled ? 'Yes' : 'No' }}
        </b-checkbox>
        <span v-else>{{ props.row.enabled ? 'Yes' : 'No' }}</span>
      </b-table-column>

      <b-table-column field="id" label="Id" width="150" v-slot="props">
        <router-link :to="{ name: 'integrations-webhook', params: { id: props.row.id }, query: routerQuery }">
          {{ props.row.id }}
        </router-link>
      </b-table-column>

      <b-table-column field="attributes" label="Attributes" width="150" v-slot="props">
        {{ props.row.attributes }}
      </b-table-column>

      <b-table-column field="action" v-if="editable" v-slot="props">
        <a v-on:click="remove(props.row)">
          <b-icon title="Remove" icon="minus-circle" size="is-mediu" type="is-danger"></b-icon>
        </a>
      </b-table-column>

      <template #detail="props">
        <IntegrationListDetail :value="props.row" @update="updateIntegration" :editable="editable">
        </IntegrationListDetail>
      </template>

      <template slot="empty">Empty</template>

      <template slot="footer" v-if="editable && organization_id">
        <td colspan="2">
          <AutocompleteSelect
            v-model="integration"
            :uri="`/v1/integrations?organization_id=${organization_id}`"
            :formatter="(x) => x && `${x.name} (${x.type})`"
          ></AutocompleteSelect>

          <b-button @click="add" type="is-info" size="is-small">
            <i class="mdi mdi-plus-circle" title="Add"></i> Add
          </b-button>
        </td>
      </template>
    </b-table>
  </div>
</template>
<script>
import { mapActions } from 'vuex';
import AutocompleteSelect from './AutocompleteSelect.vue';
import IntegrationListDetail from './IntegrationListDetail.vue';

export default {
  name: 'IntegrationList',
  props: {
    value: { required: true },
    organization_id: { required: true },
    editable: { default: false },
  },
  components: { AutocompleteSelect, IntegrationListDetail },
  data() {
    return {
      integration: null,
      integrations: [],
    };
  },
  mounted() {},
  computed: {
    routerQuery() {
      if (this.$route.name === 'device') {
        return { deviceId: this.$route.params.id };
      }
      if (this.$route.name === 'group') {
        return { groupId: this.$route.params.id };
      }
      return null;
    },
  },
  methods: {
    ...mapActions(['fetch_integration']),
    add() {
      if (!this.integration) return;
      const newValue = [];
      for (let index = 0; index < this.value.length; index += 1) {
        const item = this.value[index];
        if (item.id === this.integration) return;
        newValue.push(item);
      }
      newValue.push({
        id: this.integration,
        enabled: true,
        attributes: {},
      });
      this.$emit('input', newValue);
      this.integration = null;
    },
    remove(item) {
      const newValue = this.value.filter((x) => x.id !== item.id);
      this.$emit('input', newValue);
    },
    updateIntegration(newItemValue) {
      this.$emit(
        'input',
        this.value.map((item) => {
          if (item.id === newItemValue.id) {
            item.attributes = newItemValue.attributes; // eslint-disable-line no-param-reassign
            item.attributesError = newItemValue.attributesError; // eslint-disable-line no-param-reassign
          }
          return item;
        })
      );
    },
    updateIntegrationEnabled(newItemValue) {
      this.$emit(
        'input',
        this.value.map((item) => {
          if (item.id === newItemValue.id) {
            item.enabled = newItemValue.enabled; // eslint-disable-line no-param-reassign
          }
          return item;
        })
      );
    },
    async update() {
      this.integrations = await Promise.all(
        this.value.map(async (item) => {
          const integration = await this.fetch_integration(item.id);
          return {
            id: item.id,
            enabled: item.enabled,
            name: integration.name,
            type: integration.type,
            attributes: item.attributes,
            attributesError: item.attributesError,
          };
        })
      );
    },
  },
  watch: {
    value() {
      this.update();
    },
  },
};
</script>
