<template>
  <b-datepicker
    v-model="date"
    class="is-active"
    :first-day-of-week="1"
    style="width: 130px"
    placeholder="Click to select"
    :min-date="minDateDate"
    :max-date="maxDateDate"
    editable
  >
    <b-button type="is-primary" size="is-small" @click="date = new Date()">
      <b-icon icon="calendar-today" size="is-small"></b-icon><span>Today</span>
    </b-button>

    <b-button type="is-danger" size="is-small" @click="date = null">
      <b-icon icon="close" size="is-small"></b-icon><span>Clear</span>
    </b-button>
  </b-datepicker>
</template>

<script>
import moment from 'moment';

export default {
  name: 'Datepicker',
  props: {
    value: {
      required: true,
    },
    minDate: {},
    maxDate: {},
  },
  computed: {
    date: {
      get () {
        return this.value ? moment(this.value).toDate() : null;
      },
      set (newValue) {
        this.$emit('input', newValue ? moment(newValue).format('YYYY-MM-DD') : null);
      },
    },
    minDateDate() {
      return this.minDate ? moment(this.minDate).toDate() : null;
    },
    maxDateDate() {
      return this.maxDate ? moment(this.maxDate).toDate() : null;
    },
  },
};
</script>
